
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAgent from "@/composables/useAgents";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  IGetAgentForViewDto,
  AgentDto,
} from "@/shared/service-proxies/service-proxies";
import useTerritories from "@/composables/useTerritories";
import { TerritoriesFilter } from "@/models";

export default defineComponent({
  components: {},
  setup() {
    const { agent, getAgent } = useAgent();
    const { getTerritories, territories } = useTerritories();

    const route = useRoute();
    const router = useRouter();
    const territoryName = ref<string>("");

    let modelValue = ref<IGetAgentForViewDto>({
      agent: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        userId: "",
        territoryId: "",
        id: "",
      } as unknown as AgentDto,
      territoryName: "string ",
    });
    const breadcrumbs = {
      title: "Product Details",
      breadcrumbs: ["Product", "Details"],
    };
    onMounted(async () => {
      const defaultTerritoryFilter = {
        filter: null,
        territoryCodeFilter: null,
        territoryNameFilter: null,
        territoryDescriptionFilter: null,
        sorting: null,
        skipCount: undefined,
        maxResultCount: 1000,
        cancelToken: null,
      } as unknown as TerritoriesFilter;
      // setCurrentPageBreadcrumbs("Products Details", ["Product", "Details"]);

      await getAgent(route.params.id as string);

      modelValue.value = agent.value;

      await getTerritories(defaultTerritoryFilter);

      territories.value.map((territory) => {
        if (territory.territory.id === modelValue.value.agent.territoryId)
          territoryName.value = territory.territory.territoryName as string;
      });
    });

    return {
      router,
      modelValue,
      territoryName,
      breadcrumbs,
    };
  },
});
