import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { AgentsFilter } from "@/models";
import {
  AgentsServiceProxy,
  IGetAgentForViewDto,
  CreateOrEditAgentDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableAgents {
  agents: Ref<Array<IGetAgentForViewDto>>;
  agent: Ref<IGetAgentForViewDto>;
  getAgents: (filters: AgentsFilter) => void;
  getAgent: (id: string) => void;
  deleteAgent: (id: string) => Promise<boolean>;
  updateOrCreateAgent: (agent: CreateOrEditAgentDto) => void;
}

const useAgents = (): UsableAgents => {
  const agents: Ref<Array<IGetAgentForViewDto>> = ref(
    [] as unknown as Array<IGetAgentForViewDto>
  );

  const client = new AgentsServiceProxy(APP_BASE_URL, axiosClient);

  const agent: Ref<IGetAgentForViewDto> = ref(
    null as unknown as IGetAgentForViewDto
  );

  const store = useStore();

  const getAgents = async (filters: AgentsFilter) => {
    const [
      filter,
      firstnameFilter,
      lastnameFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    ] = [...Object.values(filters)];

    await client
      .getAll(
        filter,
        firstnameFilter,
        lastnameFilter,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        agents.value = data.items as unknown as Array<IGetAgentForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getAgent = async (id: string) => {
    await client
      .getAgentForView(id)
      .then((data) => {
        agent.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const updateOrCreateAgent = async (agent: CreateOrEditAgentDto) => {
    await client.createOrEdit(agent).catch((error) => {
      store.commit(Mutations.SET_ERROR, error);
    });
  };

  const deleteAgent = async (id: string) => {
    await client.delete(id).catch((error) => {
      store.commit(Mutations.SET_ERROR, error);
      return false;
    });

    return true;
  };

  return {
    agents,
    agent,
    getAgents,
    getAgent,
    deleteAgent,
    updateOrCreateAgent,
  };
};

export default useAgents;
